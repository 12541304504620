import * as rewardProductCategoriesApi from '@/services/reward-product-categories.js'
// import { getHistory } from '@/services/resource'
import searchForm from '@/components/searchForm'
import importButton from '@/components/importButton'
import allButton from '@/components/allButton'
// import dropListCollect from '@/components/dropListCollect'
import createTable from '@/components/createTable'
import { mixin } from '@/mixins/mixin'
import jsonData from './jsonData.js'
import _ from 'lodash'
import { mapState } from 'vuex'
import loading from '@/utils/loading.json'
export default {
  name: 'managerProduct',
  components: {
    searchForm,
    // alterForm,
    createTable,
    // dropListCollect,
    importButton,
    allButton
    // topTabBtn
  },
  mixins: [mixin],
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  computed: {
    ...mapState({
      phone: state => state.app.phone
    })
  },
  created () {
    // console.log(this.$route)
    this.queryData(rewardProductCategoriesApi.getSetup)
  },
  mounted () {
  },
  watch: {
    $route (to, from) {
      if (from.name === 'managerProductEdit') {
        this.$nextTick(() => {
          this.search()
          this.$refs.createTable.$refs.table.doLayout()
        })
      }
    }
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // tab切换
    tabChange (val) {
      const index = this.dropDataItem.findIndex(item => item.value === this.tabData[val].value)
      const data = _.cloneDeep(this.deepDropItem)
      if (index !== -1) {
        data.splice(index, 1)
        this.dropDataItem = data
      } else {
        this.dropDataItem = data
      }
      // data.splice()
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      rewardProductCategoriesApi.getRewardProductCategories(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 点击操作按钮
    handleClick (type, data) {
      const _this = this
      if (type === 'create') {
        this.$router.push({
          name: 'rewardsStoreProductCategoriesAdd'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'rewardsStoreProductCategoriesDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'rewardsStoreProductCategoriesEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        _this.$confirm(_this.$t('text.confirmDeleteThisItem'), _this.$t('text.confirmation'), {
          type: 'warning'
        }).then(() => {
          _this.setGlobalLoading(true)
          rewardProductCategoriesApi.deleteId(data.id.value).then(res => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => _this.setGlobalLoading(false))
        }).catch(err => err)
      }
    },
    // 切换伸缩框
    handleChange (val) {
      // console.log(val)
    },
    // 删除数据
    deleteClick (data) {
    },
    // 数据的选择
    selectionChange (val) {
      this.selectionData = val
    }
  }
}
